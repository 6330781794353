import React, { useContext, useEffect, useState } from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/layout";
import { navigate } from "gatsby";
import auth0 from "../../../util/auth0";
import AppContext from "../../../AppContext";


const Sudoku = loadable(() => import("@cg-squad/sudoku-client"), {
  resolveComponent: (mod) => mod.Sudoku,
});

const Play = (props) => {
  const [token, setToken] = useState(null);

  const logout = async () => {
    auth0.extended.logout()
    navigate("/auth/signin")
  };
  const appContext = useContext(AppContext);

  useEffect(() => {
    document.body.style.padding=0
    if (!appContext.sudokuData) {
      onBack();
      return;
    }
    const accessToken = auth0.extended.getAccessToken();
    setToken(`Bearer ${accessToken}`);
  }, []);
  const onBack = async () => {
    await navigate("/games/sudoku");
    appContext.updateState("sudokuData", null);
  };

  return (
    <Layout hideHeader={true} hideFooter={true}>
      {token ? (
        <Sudoku
          token={token}
          apiUrl={process.env.GATSBY_SUDOKU_SERVER_URL}
          onLogout={logout}
          onTournamentClick={() => {}}
          data={appContext?.sudokuData || null}
          onBack={onBack}
        />
      ) : (
        <div className={"w-full h-[90vh] flex justify-center items-center"}>
          <div className="lds-ellipsis w-full h-full">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Play;
